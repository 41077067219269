import request from '@/utils/request';

export function graphicsCode(params) {
  return request({
    url: '/api/login/graphicsCode',
    method: 'get',
    responseType: 'blob',
    params,
  });
}
export function smsCode(params) {
  return request({
    url: '/api/login/smsCode',
    method: 'get',
    params,
  });
}
export default { graphicsCode, smsCode };
