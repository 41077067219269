<template>
  <div class="performance">
    <div class="tit">
      {{ type }}
    </div>
    <div class="content">
      <div class="content-left">
        <a-steps
          direction="vertical"
          size="small"
          :current="active"
        >
          <a-step title="投保人信息" />
          <a-step title="保函基本信息" />
          <a-step title="标的信息" />
          <a-step title="申请资料" />
          <a-step title="申请成功" />
        </a-steps>
      </div>
      <!-- 投保人信息 -->
      <div
        class="content-right"
        v-show="active==1"
      >
        <div class="right-tit">
          投保人信息
        </div>
        <div class="right-con">
          <a-form-model
            ref="form1"
            :model="applicantInform"
            :rules="rule1"
            :label-col="{xs:{span:24},sm:{span:8},}"
            :wrapper-col="{xs:{span:24},sm:{span:12},}"
          >
            <a-form-model-item label="投保人名称">
              <div>{{ applicantInform.name }}</div>
            </a-form-model-item>
            <a-form-model-item label="社会统一信用代码">
              <div>{{ applicantInform.num }}</div>
            </a-form-model-item>
            <a-form-model-item
              label="手机号"
              prop="responsiblePersonPhone"
            >
              <a-input v-model="applicantInform.responsiblePersonPhone" />
            </a-form-model-item>
            <a-form-model-item
              label="联系人"
              prop="responsiblePersonName"
            >
              <a-input v-model="applicantInform.responsiblePersonName" />
            </a-form-model-item>
            <!-- <a-form-model-item
              label="验证码"
              prop="code"
            >
              <div style="display:flex;">
                <a-input v-model="applicantInform.code" />
                <img
                  :src="codeImg"
                  alt=""
                  style="width: 235px;height: 31px;margin-left: 17px;"
                  @click="getImgcode"
                >
              </div>
            </a-form-model-item>
            <a-form-model-item
              label="手机验证码"
              prop="phoneCode"
            >
              <div style="display:flex;">
                <a-input v-model="applicantInform.phoneCode" />
                <a-button
                  type="link"
                  v-if="isTime"
                  @click="getCode"
                >
                  获取手机验证码
                </a-button>
                <span
                  v-else
                  style="width:200px;margin-left:20px"
                >{{ time }}s重新获取</span>
              </div>
            </a-form-model-item> -->
            <!-- <a-form-model-item
              label="联系地址"
              prop="creditCode4"
            >
              <a-input v-model="applicantInform.creditCode4" />
            </a-form-model-item> -->
            <a-form-model-item class="nextBtn">
              <div style="display:flex;">
                <a-button
                  type="primary"
                  @click="handleReset"
                >
                  重置
                </a-button>
                <a-button
                  type="primary"
                  @click="handleSubmit"
                >
                  下一步
                </a-button>
              </div>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
      <!-- 保函基本信息 -->
      <div
        class="content-right"
        v-if="active==2"
      >
        <div class="right-tit">
          保函基本信息
        </div>
        <div class="right-con">
          <a-form-model
            ref="form2"
            :model="ensureMoney"
            :rules="rule2"
            :label-col="{xs:{span:24},sm:{span:6},}"
            :wrapper-col="{xs:{span:24},sm:{span:12},}"
          >
            <a-form-model-item label="保函类型">
              <div>{{ ensureMoney.type }}</div>
            </a-form-model-item>
            <a-form-model-item
              label="项目名称"
              prop="projectName"
            >
              <a-input v-model="ensureMoney.projectName" />
            </a-form-model-item>
            <a-form-model-item
              label="项目编号"
              prop="projectCode"
            >
              <a-input v-model="ensureMoney.projectCode" />
            </a-form-model-item>
            <a-form-model-item
              label="标段名称"
              prop="sectionName"
            >
              <a-input v-model="ensureMoney.sectionName" />
            </a-form-model-item>
            <a-form-model-item
              label="标段编号"
              prop="sectionCode"
            >
              <a-input v-model="ensureMoney.sectionCode" />
            </a-form-model-item>
            <a-form-model-item
              label="保证金额(元)"
              prop="sumAmount"
            >
              <!-- <div style="display:flex;">
                <a-input v-model="ensureMoney.sumAmount" />
                <div style="width:228px;margin-left: 18px;">
                  大写金额:XXX元整
                </div>
              </div> -->
              <a-input
                v-model.number="ensureMoney.sumAmount"
                @change="changeSumAmount"
              />
            </a-form-model-item>
            <a-form-model-item
              label="保证期限"
              prop="date"
            >
              <a-range-picker
                v-model="ensureMoney.date"
                :locale="locale"
                :disabled-date="disabledDate"
                :disabled-time="disabledRangeTime"
                format="YYYY-MM-DD"
                v-if="isOpen"
                @change="onChange"
              />
            </a-form-model-item>
            <a-form-model-item
              label="预计保费(元)"
            >
              <span>{{ ensureMoney.result }}</span>
            </a-form-model-item>
            <div style="color:red;display: flex;justify-content: center;">
              *实际需支付金额以实际审核后显示为准
            </div>
            <a-form-model-item class="nextBtn nextBtns">
              <div style="display:flex;">
                <!-- <a-button
                  type="primary"
                  @click="handleReset"
                >
                  重置
                </a-button> -->
                <div style="display:flex;">
                  <a-button
                    type="primary"
                    @click="handleReset"
                  >
                    重置
                  </a-button>
                  <a-button
                    type="primary"
                    @click="onBack"
                  >
                    上一步
                  </a-button>
                  <a-button
                    type="primary"
                    @click="handleSubmit"
                  >
                    下一步
                  </a-button>
                </div>
              </div>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
      <!-- 标的信息 -->
      <div
        class="content-right"
        v-if="active==3"
      >
        <div class="right-tit">
          标的信息
        </div>
        <div class="right-con">
          <a-form-model
            ref="form3"
            class="form3"
            :model="subjectInform"
            :rules="rule3"
            :label-col="{span: 12}"
            :wrapper-col="{ span: 12 }"
          >
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-model-item
                  label="工程项目名称"
                  prop="contractName"
                >
                  <a-input v-model="subjectInform.contractName" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item
                  label="工程合同编号"
                  prop="contractNumber"
                >
                  <a-input v-model="subjectInform.contractNumber" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-model-item
                  label="工程合同类型"
                  prop="contractType"
                >
                  <a-select
                    v-model="subjectInform.contractType"
                    style="width: 120px"
                  >
                    <a-select-option
                      v-for="item in contractType"
                      :key="item.id"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item
                  label="工程建设领域"
                  prop="contractField"
                >
                  <a-select
                    v-model="subjectInform.contractField"
                    style="width: 120px"
                  >
                    <a-select-option
                      v-for="item in contractField"
                      :key="item.id"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-model-item
                  label="是否公建项目"
                  prop="whetherPublicBuild"
                >
                  <a-select
                    v-model="subjectInform.whetherPublicBuild"
                    style="width: 120px"
                  >
                    <a-select-option
                      v-for="item in commonWhether"
                      :key="item.id"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item
                  label="预计合同价款(元)"
                  prop="contractAmount"
                >
                  <a-input v-model.number="subjectInform.contractAmount" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-model-item
                  label="开工日期(预计)"
                  prop="projectStartTime"
                >
                  <a-date-picker
                    v-model="subjectInform.projectStartTime"
                    :locale="locale"
                    :disabled-date="disabledDate"
                    :disabled-time="disabledRangeTime"
                    format="YYYY-MM-DD"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item
                  label="竣工日期(预计)"
                  prop="projectEndTime"
                >
                  <a-date-picker
                    v-model="subjectInform.projectEndTime"
                    :locale="locale"
                    :disabled-date="disabledDate"
                    :disabled-time="disabledRangeTime"
                    format-value="YYYY-MM-DD"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-model-item
                  label="工程所在地区"
                  prop="projectArea"
                >
                  <a-input v-model="subjectInform.projectArea" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item
                  label="工程详细地址"
                  prop="projectAddress"
                >
                  <a-input v-model="subjectInform.projectAddress" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-form-model-item class="nextBtn">
              <div style="display:flex;">
                <a-button
                  type="primary"
                  @click="handleReset"
                >
                  重置
                </a-button>
                <a-button
                  type="primary"
                  @click="onBack"
                >
                  上一步
                </a-button>
                <a-button
                  type="primary"
                  @click="handleSubmit"
                >
                  下一步
                </a-button>
              </div>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
      <!-- 申请资料 -->
      <div
        class="content-right"
        v-if="active==4"
      >
        <div class="right-tit">
          申请资料
        </div>
        <div class="right-tips">
          请以word、pdf或图片形式上传下述资料
        </div>
        <div class="right-con applyItem">
          <a-form-model
            ref="form4"
            :model="subjectInform"
            :rules="rule4"
            :label-col="{xs:{span:24},sm:{span:9},}"
            :wrapper-col="{xs:{span:24},sm:{span:12},}"
          >
            <a-row
              :gutter="24"
              v-for="(item,index) in filesList"
              :key="index"
            >
              <a-col :span="12">
                <a-form-model-item :label="item.label">
                  <a-upload
                    name="file"
                    :multiple="true"
                    :action="VUE_APP_FIRST_URL+'/api/insure/apply/uploadFile'"
                    :data="{insureId:insureId,dictId:item.id,file:fileLists}"
                    :default-file-list="files"
                    @change="handleChange"
                    @preview="handlePreview"
                  >
                    <a-button
                      type="link"
                      @click="getFile(item)"
                    >
                      点击上传
                    </a-button>
                    <!-- <a-button
                      type="link"
                      @click="handlePreview(item.filePath)"
                    >
                      点击下载
                    </a-button> -->
                  </a-upload>
                </a-form-model-item>
              </a-col>
              <a-col :span="12" />
            </a-row>
            <a-form-model-item class="nextBtn">
              <div style="display:flex;">
                <a-button
                  type="primary"
                  @click="onBack"
                >
                  上一步
                </a-button>
                <a-button
                  type="primary"
                  @click="application"
                >
                  提交申请
                </a-button>
              </div>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
      <!-- 申请成功 -->
      <div
        class="content-right content-success"
        v-if="isSuccess"
      >
        <div class="success-tit">
          {{ type }}
        </div>
        <a-button
          type="link"
          @click="$router.push('./orderCenter')"
        >
          点击跳转账户中心查看
        </a-button>
      </div>
    </div>
    <div class="notice-dialog">
      <a-modal
        v-model="modal2Visible"
        title="投保须知"
        centered
        cancel-text="不同意取消投保"
        ok-text="我已阅读并同意投保须知"
        :mask-closable="false"
        @cancel="()=>($router.push('./'))"
        @ok="() => (modal2Visible = false)"
      >
        <p>投保须知文案</p>
      </a-modal>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { stepFirst, stepSecond, stepThird, dictDetail, stepForth, detail, delUploadFile, readFile } from '@/api/performance.js';
import { graphicsCode, smsCode } from '@/api/code.js';
import { info } from '@/api/user/user.js';
import { entInfo } from '@/api/entCertification/entCertification.js';
export default {
  data() {
    const compareToFirstPhone = (rule, value, callback) => {
      const pattern = /^1[3|4|5|7|8|9][0-9]{9}$/;
      if (pattern.test(value)) {
        callback();
      } else if (!value) {
        callback('请输入手机号');
      } else {
        callback('手机号格式不正确');
      }
    };

    return {
      moment,
      locale,
      active: 1,
      fileLists: [],
      isSuccess: false,
      // headers: { authorization: 'authorization-text' },
      type: '2',
      modal2Visible: true,
      codeImg: '',
      isTime: true,
      time: 10,
      insureId: '',
      isOpen: true,
      applicantInform: {},
      ensureMoney: { data: [] },
      filesList: [],
      files: [],
      subjectInform: {},
      VUE_APP_FIRST_URL: '',
      orderNo: '',
      fileId: '',
      rule1: {
        responsiblePersonPhone: [
          { required: true, validator: compareToFirstPhone, trigger: 'blur' },
        ],
      },
      rule2: {
        projectName: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
        projectCode: [{ required: true, message: '请输入项目编号', trigger: 'blur' }],
        sectionName: [{ required: true, message: '请输入标段名称', trigger: 'blur' }],
        sectionCode: [{ required: true, message: '请输入标段编号', trigger: 'blur' }],
        sumAmount: [{ type: 'number', required: true, message: '请输入保证金额', trigger: 'blur' }],
        date: [{ required: true, message: '请选择保证期限', trigger: 'blur' }],
        result: [{ required: true, message: '请输入预计保费', trigger: 'blur' }],
      },
      rule3: {
        contractName: [{ required: true, message: '请输入工程项目名称', trigger: 'blur' }],
        contractNumber: [{ required: true, message: '请输入工程合同编号', trigger: 'blur' }],
        contractType: [{ required: true, message: '请输入工程合同类型', trigger: 'blur' }],
        contractField: [{ required: true, message: '请输入工程建设领域', trigger: 'blur' }],
        whetherPublicBuild: [{ required: true, message: '请输入是否公建项目', trigger: 'blur' }],
        contractAmount: [{ type: 'number', required: true, message: '请输入预计合同价款', trigger: 'blur' }],
        projectStartTime: [{ required: true, message: '请输入开工日期', trigger: 'blur' }],
        projectEndTime: [{ required: true, message: '请输入竣工日期', trigger: 'blur' }],
        projectArea: [{ required: true, message: '请输入工程所在地区', trigger: 'blur' }],
        projectAddress: [{ required: true, message: '请输入工程详细地址', trigger: 'blur' }],

      },
      rule4: {},
      // 字典
      contractType: [],
      contractField: [],
      commonWhether: [],
      minPremium: null, // 最低保费
      rate: null, // 费率
    };
  },
  mounted() {
    this.getInit();
  },
  methods: {
    getInit() {
      this.orderNo = this.$route.query.orderNo;
      this.VUE_APP_FIRST_URL = process.env.VUE_APP_FIRST_URL;
      this.ensureMoney.result = window.localStorage.getItem('result');
      this.ensureMoney.type = this.type = this.$route.query.type === '2' ? '履约保函申请' : '农民工工资保函申请';
      this.info();
      this.getImgcode();
      this.getDictDetail();
      this.orderNo ? this.getDetail() : '';
    },
    info() {
      entInfo().then(res => {
        if (res.data.status === 'SUCCESS') {
          this.data = res.data.data;
          this.$store.state.info = res.data.data;
          this.applicantInform.name = res.data.data.companyName;
          this.applicantInform.num = res.data.data.creditCode;
        }
      });
      info().then(res => {
        if (res.data.status === 'SUCCESS') {
          if (res.data.data.discount) {
            this.discount = res.data.data.discount;
            this.$store.state.discount = res.data.data.discount;
          }
          this.certificationFlag = res.data.data.certificationFlag;
          this.phone = res.data.data.phoneNum;
          sessionStorage.setItem('username', JSON.stringify(res.data.data.username));
        } else {
          if (window.location.pathname === process.env.VUE_APP_FIRST_URL + '/performance') {
            // if (res.data.errorMsg === '用户未登录') {
            this.$router.push({ name: 'login' });
            // }
          }
        }
      });
    },
    getDetail() {
      detail({ orderNo: this.orderNo }).then(res => {
        this.insureId = res.data.data.insureInfo.insureId;
        this.applicantInform = {};
        this.ensureMoney = {};
        this.$nextTick(function() {
          this.applicantInform = { ...res.data.data.insureInfo };
          this.ensureMoney = { ...res.data.data.insureInfo };
          this.subjectInform = res.data.data.insureInfo;
          this.ensureMoney.type = this.type = this.$route.query.type === '2' ? '履约保函申请' : '农民工工资保函申请';
          this.ensureMoney.result = window.localStorage.getItem('result');
          this.ensureMoney.date = res.data.data.insureInfo.startDate ? [ res.data.data.insureInfo.startDate, res.data.data.insureInfo.endDate ] : this.$set(this.ensureMoney, 'date', []);
          this.minPremium = res.data.data.minPremium;
          this.rate = res.data.data.rate;
          this.changeSumAmount({ target: { value: res.data.data.insureInfo.sumAmount } });
        });
        res.data.data.fileList.forEach(item => {
          item.label = item.fileDescribe;
        });
        this.filesList = res.data.data.fileList;
        this.files = res.data.data.fileList.map(item => {
          return {
            uid: item.id,
            name: item.fileName || item.fileDescribe,
            status: 'done',
            url: item.filePath,
            filePath: item.filePath,
          };
        });
      });
    },
    getImgcode() {
      graphicsCode({ source: 'ACC_USER_LOGIN' }).then(res => {
        this.codeImg = window.URL.createObjectURL(res.data);
      });
    },
    getCode() {
      this.timer = '';
      if (this.applicantInform.code !== '' && this.applicantInform.responsiblePersonPhone !== '' && !!this.applicantInform.code) {
        smsCode({ source: 'ACC_USER_LOGIN', checkCode: this.applicantInform.code, phoneNum: this.applicantInform.responsiblePersonPhone }).then(res => {
          this.isCode = res.data;
          if (res.data.status === 'FAIL') {
            this.$message.warning(this.isCode.errorMsg);
          } else {
            this.isTime = false;
            this.timer = setInterval(this.countDown, 1000);
          }
        });
      } else {
        this.$message.warning('手机号码和图形验证码不为空');
      }
    },
    countDown() {
      if (this.time > 0) {
        this.time--;
      } else {
        this.time = 10;
        this.isTime = true;
        clearInterval(this.timer);
      }
    },
    changeSumAmount(e) {
      console.log(e.target);
      const money = (Number(e.target.value) * this.rate).toFixed(2);
      console.log();
      if (money >= this.minPremium) {
        this.ensureMoney.result = money;
        console.log(money);
      } else {
        console.log(this.minPremium.toFixed(2));
        this.ensureMoney.result = this.minPremium.toFixed(2);
      }
    },
    getDictDetail() {
      dictDetail({ dictName: 'performanceApplyFileType' }).then(res => {
        this.filesList = res.data.data;
      });
      dictDetail({ dictName: 'contractType' }).then(res => {
        this.contractType = res.data.data;
      });
      dictDetail({ dictName: 'contractField' }).then(res => {
        this.contractField = res.data.data;
      });
      dictDetail({ dictName: 'commonWhether' }).then(res => {
        this.commonWhether = res.data.data;
      });
    },
    onBack() {
      this.active--;
      if (this.active === 1) {
        const applicant = this.applicantInform;
        this.applicantInform = {};
        this.$nextTick(function() {
          this.applicantInform = { ...applicant };
        });
      } else if (this.active === 2) {
        const applicant = this.ensureMoney;
        this.ensureMoney = {};
        this.$nextTick(function() {
          this.ensureMoney = { ...applicant };
        });
      } else if (this.active === 3) {
        const applicant = this.subjectInform;
        this.subjectInform = {};
        this.$nextTick(function() {
          this.subjectInform = { ...applicant };
        });
      }
    },
    application() {
      stepForth({ insureId: this.insureId }).then(res => {
        console.log(res);
        this.active++;
        this.isSuccess = true;
      }).catch(err => {
        console.log(err);
      });
    },
    handleSubmit() {
      if (this.active === 1) {
        this.$refs.form1.validate(valid => {
          if (valid) {
            const params = {
              insureId: this.insureId,
              // phoneCode: this.applicantInform.phoneCode,
              responsiblePersonPhone: this.applicantInform.responsiblePersonPhone,
              responsiblePersonName: this.applicantInform.responsiblePersonName,
            };
            stepFirst(params).then(res => {
              if (res.data.status === 200) {
                this.insureId = res.data.data ? res.data.data.id : this.insureId;
                this.active++;
              }
            }).catch(err => {
              this.$notification.warning({ message: err.response.data.message });
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else if (this.active === 2) {
        this.$refs.form2.validate(valid => {
          if (valid) {
            const params = {
              insureId: this.insureId,
              projectName: this.ensureMoney.projectName,
              projectCode: this.ensureMoney.projectCode,
              sectionName: this.ensureMoney.sectionName,
              sectionCode: this.ensureMoney.sectionCode,
              sumAmount: this.ensureMoney.sumAmount,
              result: this.ensureMoney.result,
              startDate: this.ensureMoney.date.length === 0 ? '' : moment(this.ensureMoney.date[0]).format('YYYY-MM-DD'),
              endDate: this.ensureMoney.date.length === 0 ? '' : moment(this.ensureMoney.date[1]).format('YYYY-MM-DD'),
            };
            stepSecond(params).then(res => {
              if (res.data.status === 'FAIL') {
                this.$notification.warning({ message: res.data.errorMsg });
                return;
              }
              this.active++;
            }).catch(err => {
              this.$notification.warning({ message: err.response.data.message });
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else if (this.active === 3) {
        this.$refs.form3.validate(valid => {
          if (valid) {
            const params = {
              insureId: this.insureId,
              contractName: this.subjectInform.contractName,
              contractNumber: this.subjectInform.contractNumber,
              contractType: this.subjectInform.contractType,
              contractField: this.subjectInform.contractField,
              whetherPublicBuild: this.subjectInform.whetherPublicBuild,
              contractAmount: this.subjectInform.contractAmount,
              projectStartTime: moment(this.subjectInform.projectStartTime).format('YYYY-MM-DD'),
              projectEndTime: moment(this.subjectInform.projectEndTime).format('YYYY-MM-DD'),
            };
            stepThird(params).then(res => {
              console.log(res);
              this.active++;
              if (!this.orderNo) {
                this.getDictDetail();
              }
            }).catch(err => {
              this.$notification.warning({ message: err.response.data.message });
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }

    },
    handleReset() {
      if (this.active === 1) {
        this.$refs.form1.resetFields();
      } else if (this.active === 2) {
        this.ensureMoney = { data: [] };
        this.ensureMoney.result = window.localStorage.getItem('result');
        this.ensureMoney.type = this.type = this.$route.query.type === '2' ? '履约保函申请' : '农民工工资保函申请';
        this.$refs.form2.resetFields();
      } else if (this.active === 3) {
        this.$refs.form3.resetFields();
      }
    },
    getFile(item) {
      this.fileId = item.id;
    },
    handleChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name}上传成功`);
        this.fileId = info.fileList[0].response.data.id;
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`);
      } else if (info.file.status === 'removed') {
        this.fileId = this.orderNo ? info.file.uid : this.fileId;
        delUploadFile({ insureId: this.insureId, fileId: this.fileId }).then(res => {
          console.log(res);
          this.$message.success(`${info.file.name}删除成功`);
        });
      }
      this.fileLists = info.file;
    },
    downLoad(url) {
      readFile({ source: url }).then(res => {
        console.log(res);
      });
    },
    // 预览
    handlePreview(file) {
      const url = this.orderNo ? file.filePath : file.response.data.filePath;
      const a = document.createElement('a');
      a.href = this.VUE_APP_FIRST_URL + '/api/files/readFile?source=' + url;
      // a.href = 'http://yinsheng.qa.rdtest.cn/api/files/readFile?source=' + url;
      a.click();
    },
    setModal1Visible(modal1Visible) {
      this.modal1Visible = modal1Visible;
    },
    onChange() {
      this.isOpen = false;
      this.$nextTick(function() {
        this.isOpen = true;
      });
    },
    // 此部分为日期选择的配置,一般不做处理--开始
    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');// 不包含今天
      // return current < moment().subtract(1, 'day');// 包含今天
    },
    disabledDateTime() {
      return {
        disabledHours: () => this.range(0, 24).splice(4, 20),
        disabledMinutes: () => this.range(30, 60),
        disabledSeconds: () => [ 55, 56 ],
      };
    },
    disabledRangeTime(_, type) {
      if (type === 'start') {
        return {
          disabledHours: () => this.range(0, 60).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [ 55, 56 ],
        };
      }
      return {
        disabledHours: () => this.range(0, 60).splice(20, 4),
        disabledMinutes: () => this.range(0, 31),
        disabledSeconds: () => [ 55, 56 ],
      };
    },
    // 此部分为日期选择的配置,一般不做处理--结束
  },
};
</script>
<style lang="less" scoped>
@import "./components/common.less";
.form3 {
  ::v-deep .ant-form-item-label {
    width: 134px;
  }
}
</style>
