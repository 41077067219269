import request from '@/utils/request';

export function stepFirst(data) {
  return request({
    url: '/api/insure/apply/stepFirst',
    method: 'post',
    data,
  });
}


export function stepSecond(data) {
  return request({
    url: '/api/insure/apply/stepSecond',
    method: 'post',
    data,
  });
}

export function stepThird(data) {
  return request({
    url: '/api/insure/apply/stepThird',
    method: 'post',
    data,
  });
}

export function dictDetail(params) {
  return request({
    url: '/api/dictDetail',
    method: 'get',
    params,
  });
}

export function stepForth(data) {
  return request({
    url: '/api/insure/apply/stepForth',
    method: 'post',
    data,
  });
}

export function detail(params) {
  return request({
    url: '/api/insure/apply/detail',
    method: 'get',
    params,
  });
}

export function delUploadFile(data) {
  return request({
    url: '/api/insure/apply/delUploadFile',
    method: 'post',
    data,
  });
}

export function readFile(params) {
  return request({
    url: '/api/files/readFile',
    method: 'get',
    params,
  });
}

export default { stepFirst, stepSecond, stepThird, dictDetail, detail, delUploadFile, readFile };
